<template>
	<header class="header-global">
		<base-nav class="navbar-main" transparent type="" effect="light" expand>
			<router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
				<img src="img/wsam-logo.png" alt="logo" />
			</router-link>

			<div class="row" slot="content-header" slot-scope="{ closeMenu }">
				<div class="col-6 collapse-brand">
					<a href="/">
						<img src="img/wsam-logo.png" />
					</a>
				</div>
				<div class="col-6 collapse-close">
					<close-button @click="closeMenu"></close-button>
				</div>
			</div>
			<ul class="navbar-nav align-items-lg-center ml-lg-auto">
				<li class="nav-item d-none d-lg-block ml-lg-4">
					<a href="tel:+4941018317091" class="text-white"> <i class="fa fa-mobile"></i> : 04101 / 83 17 091 </a>
				</li>

				<li class="nav-item d-none d-lg-block ml-lg-4">
					<base-button tag="a" href="#kontakt" class="mb-3 mb-sm-0" type="info" icon="fa fa-code">
						Jetzt Anfragen
					</base-button>
				</li>
			</ul>
		</base-nav>
	</header>
</template>
<script>
import BaseNav from '@/components/BaseNav';
import BaseDropdown from '@/components/BaseDropdown';
import CloseButton from '@/components/CloseButton';

export default {
	components: {
		BaseNav,
		CloseButton,
		BaseDropdown,
	},
};
</script>
<style></style>
