<template>


    <section class="section section-lg section-shaped overflow-hidden my-0">


        <div class="shape shape-style-1 shape-default shape-skew">


            <span></span>


            <span></span>


            <span></span>


            <span></span>


            <span></span>


            <span></span>


        </div>


        <div class="container py-0 pb-lg">


            <div class="row justify-content-between align-items-center">


                <div class="col-lg-5 mb-5 mb-lg-0">


                    <h2 class="text-white display-3">
                         Ein kleiner Vorgeschmack?
                    </h2>


                    <p class="lead text-white mt-4">
                        Ein kleiner Auszug unserer Arbeit. Gerne erzählen wir Dir persönlich mehr.
                    </p>


                    <a href="#kontakt" class="btn btn-white mt-4">Jetzt anfragen</a>


                </div>


                <div class="col-lg-6 mb-lg-auto">


                    <div
                        class="
                            rounded
                            shadow-lg
                            overflow-hidden
                            transform-perspective-right
                        "
                    >


                        <b-carousel
                            id="carousel1"
                            controls
                            indicators
                            :interval="interval"
                        >


                            <!-- Text slides with image -->


                            <b-carousel-slide
                                img-src="img/portfolio/grill.png"
                            ></b-carousel-slide>

                            <b-carousel-slide
                                img-src="img/portfolio/lomart.png"
                            ></b-carousel-slide>


                            <b-carousel-slide
                                img-src="img/portfolio/asktraders.png"
                            ></b-carousel-slide>

                            <b-carousel-slide
                                img-src="img/portfolio/baer.png"
                            ></b-carousel-slide>


                            <b-carousel-slide
                                img-src="img/portfolio/kardio.png"
                            ></b-carousel-slide>

                            <b-carousel-slide
                                img-src="img/portfolio/talents.png"
                            ></b-carousel-slide>

                            <b-carousel-slide
                                img-src="img/portfolio/brokerdeal.png"
                            ></b-carousel-slide>


                        </b-carousel>


                    </div>


                </div>


            </div>


        </div>


    </section>


</template>


<script>
import { BCarousel } from 'bootstrap-vue/esm/components/carousel/carousel'
import { BCarouselSlide } from 'bootstrap-vue/esm/components/carousel/carousel-slide'

export default {
    data() {
        return {
            interval: 2500,
        }
    },
    components: {
        BCarousel,
        BCarouselSlide,
    },
}
</script>


<style lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
#carousel1 {
    max-height: 500px;
    @include media-breakpoint-up(lg) {
        max-height: 300px;
    }
}
</style>
