<template>
    <div class="row row-grid align-items-center">
        <div class="col-md-6" :class="{ 'order-md-2': even }">
            <!-- <img src="img/theme/promo-1.png" class="img-fluid floating w-100" /> -->
            <slot>

            </slot>
        </div>
        <div class="col-md-6" :class="{ 'order-md-1': even }">
            <div class="pr-md-5">
                <icon
                    name="ni ni-settings-gear-65"
                    class=""
                    size="lg"
                    :type="typeStyle"
                    shadow
                    rounded
                    :class="[!even ? 'float-left' : 'float-right']"
                ></icon>
                <h3
                    :class="[!even ? 'text-right' : 'text-left']"
                    class="display-3"
                >
                     {{ title }}
                </h3>
                <p class="floatfix"> {{ feature.description }} </p>
                <ul
                    class="list-unstyled mt-5"
                >
                    <li class="py-2" v-for="usp in feature.usps" :key="usp.text">
                        <div
                            class="d-flex align-items-center"
                            :class="{ 'flex-row-reverse': !even }"
                        >
                            <badge
                                :type="typeStyle"
                                circle
                                class="mr-3"
                                :icon="usp.icon"
                            ></badge>
                            <h6 class="mb-0 w-100">{{ usp.text }}</h6>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <aside  class="w-100" :class="{ 'order-md-3': even }">
            <card
                :gradient="cardStyle"
                no-body
                shadow-size="lg"
                class="border-0"
            >
                <div class="p-5">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <h3 class="text-white">
                                 {{ ctaTitle }}
                            </h3>
                        </div>


                        <div class="col-lg-3 ml-lg-auto">
                            <base-button
                                tag="a"
                                href="#kontakt"
                                type="white"
                                block
                                size="lg"
                            >
                                 Jetzt
                                <span
                                    class="text-muted d-lg-none"
                                    :class="[lightText ? 'text-white' : '']"
                                >
                                     {{ title }}
                                </span>
                                 anfragen
                            </base-button>
                        </div>
                    </div>
                </div>
            </card>
        </aside>
    </div>
</template>

<script>
export default {
    props: {
        feature: {
            type: Object,
            default: () => ({
                description: '',
                usps : []
            })
        },
        even: {
            default: false,
            type: Boolean,
        },
        lightText: {
            default: false,
            type: Boolean,
        },
        typeStyle: {
            default: 'primary',
            type: String,
        },
        title: {
            default: '',
            type: String,
        },
        cardStyle: {
            default: 'warning',
            type: String,
        },
        ctaTitle: {
            default: '',
            type: String,
        }
    },
}
</script>


<style>
/* .text-white h3,
.text-white h6 {
	color: #fff;
}*/
</style>