var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"kontaktForm",staticClass:"row justify-content-center mt--300",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"col-lg-8"},[_c('card',{attrs:{"gradient":"secondary","shadow":"","body-classes":"p-lg-5"}},[_c('h4',{staticClass:"mb-1"},[_vm._v("Du möchtest mit uns arbeiten?")]),_c('p',{staticClass:"mt-0"},[_vm._v("Wir freuen uns auf Dein Projekt.")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('base-input',{ref:"nameInput",attrs:{"alternative":"","placeholder":"Dein Name","addon-left-icon":"ni ni-single-02","aria-required":"true","error":_vm.$v.form.name.$dirty && !_vm.$v.form.name.minLength
                                    ? `Bitte gib mindestens ${_vm.$v.form.name.$params.minLength.min} Zeichen ein.`
                                    : _vm.$v.form.name.$dirty && !_vm.$v.form.name.alpha
                                        ? 'Bist du sicher, dass dies Dein richtiger Name ist?.'
                                            : _vm.$v.form.name.$dirty && !_vm.$v.form.name.required
                                                            ? 'Lass uns doch Deinen Namen wissen.'
                                                            : '',"value":_vm.form.name},on:{"blur":_vm.setName}})],1),_c('div',{staticClass:"col-md-6"},[_c('base-input',{ref:"surnameInput",attrs:{"alternative":"","placeholder":"Dein Nachname","addon-left-icon":"ni ni-single-02","aria-required":"true","error":_vm.$v.form.surname.$dirty && !_vm.$v.form.surname.minLength
                                ? `Bitte gib mindestens ${_vm.$v.form.surname.$params.minLength.min} Zeichen ein.`
                                : _vm.$v.form.surname.$dirty && !_vm.$v.form.surname.alpha
                                    ? 'Bist du sicher, dass dies Dein richtiger Nachame ist?.'
                                        : _vm.$v.form.surname.$dirty && !_vm.$v.form.surname.required
                                                        ? 'Lass uns doch Deinen Nachamen wissen.'
                                                        : '',"value":_vm.form.surname},on:{"blur":_vm.setSurname}})],1)]),_c('base-input',{attrs:{"alternative":"","placeholder":"Email Addresse","value":_vm.form.email,"addon-left-icon":"ni ni-email-83","error":_vm.getEmailError()},on:{"blur":_vm.setEmail,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setEmail.apply(null, arguments)}}}),_c('base-input',{staticClass:"mb-4"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"form-control form-control-alternative",attrs:{"name":"name","rows":"4","cols":"80","placeholder":"Hi, ich möchte gerne mit euch arbeiten. Meine aktuelle WebSeite findet ihr unter www.supercooleseite.de und ich würde gerne ..."},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "message", $event.target.value)}}})]),(_vm.success.value)?_c('div',[_c('base-alert',{attrs:{"type":"success"}},[_c('strong',[_vm._v("Success")]),_vm._v(" Vielen Dank für Deine Nachricht! ")])],1):_vm._e(),(_vm.error.value)?_c('div',[_c('base-alert',{attrs:{"type":"danger"}},[_c('strong',[_vm._v("Error")]),_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1):_vm._e(),_c('base-button',{attrs:{"disabled":!_vm.isValidated,"nativeType":"submit","round":"","block":"","size":"lg"}},[_vm._v(" Jetzt Senden ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }