<template>
    <label class="custom-toggle">
        <input type="checkbox"
          v-bind:checked="checked"
          v-on:change="$emit('change', $event.target.checked)"
          :disabled="disabled"
        >

        <span class="custom-toggle-slider rounded-circle"></span>
    </label>
</template>
<script>
export default {
  name: "base-switch",
  inheritAttrs: true,
  props: {
    checked: {
      type: Boolean,
      default: false,
      description: "Switch value"
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Switch disabled state"
    }
  },
   model: {
    prop: 'checked',
    event: 'change'
  },
};
</script>
<style>
</style>
