<template>
	<div class="profile-page">
		<section class="section-profile-cover section-shaped my-0">
			<div class="shape shape-style-1 shape-default shape-skew">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</section>
		<section class="section section-skew">
			<div class="container">
				<card shadow class="card-profile mt--300" no-body>
					<div class="px-4">
						<h1 class="text-center">{{ title }}</h1>
						<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
						<div class="content">
							<p class="pimpressum">
								<span class="impressumHeadline">Web, Services and more</span>
								<br />Ginsterweg 6 <br />25421 Pinneberg
							</p>
							<p>
								<span class="impressumHeadline">Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:</span>
								<br />31/027/61829
							</p>
							<p>
								<span class="impressumHeadline">Inhaber</span>
								<br /><a href="http://www.xing.com/profile/Christoph_Haaks" target="_blank"
									>Christoph Haaks</a
								>
							</p>

							<p class="pimpressum">
								<span class="impressumHeadline">Kontakt:</span>
								<br />Telefon: 04101 / 83 17 091 <br />Telefax: 04101 / 83 17 092 <br />Mobil:
								&nbsp;&nbsp;&nbsp;0177 / 41 86 639 <br />E-Mail:
								<a href="mailto:kontakt@wsam.biz">Kontakt@wsam.biz</a>
							</p>
							<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
							<p>
								Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
							</p>
						</div>
					</div>
				</card>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	data() {
		return {
			title: 'Impressum',
		};
	},
	    head: {
        title: function () {
            return {
                inner: this.title
            }
        },
        meta: [
            {
            name: 'description',
            content: 'Web Services and more Impressum',
            id: 'desc',

        },
            {
                name: 'robots',
                content: 'noindex, nofollow'
            }
        ]
    }
};
</script>
<style></style>
