<template>
    <SlideYUpTransition :duration="500">
        <div
            class="modal fade"
            :class="[{'show d-block': show}, {'d-none': !show}]"
                tabindex="-1"
                role="dialog"
                :aria-hidden="!show"
        >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content py-3 px-5 d-flex align-items-center align-self-center">
                        <img src="https://i.imgur.com/Tl8ZBUe.png" width="50">
                        <div v-if="!settings.show">
                            <span class="d-block">Willst du Kekse?</span>

                            <span class="mt-2">
                            Auch wir kochen nur mit Wasser und backen Webseiten mit Cookies.
                            Sprich - um dir ne echt coole Erfahrung mit uns zu bieten werden Cookies in deinem Browser gespeichert.
                           <router-link  class="d-flex align-items-center" to="/datenschutz" >Mehr erfahren</router-link>
                            </span>
                        </div>
                       <div v-else>
                        <span class="d-block">Einstellungen</span>
                        <div>
                            <span class="d-block">Essentielle Cookies</span>
                            <p>
                                Damit wir uns deine Einwilligung merken können oder für die Funktionalität der Webseite wie z.B. das Kontaktformular werden Cookies gespeichert.
                                <ul>
                                    <li>
                                        Cookie-Consent Einwilligung
                                    </li>
                                </ul>
                            </p>
                            <base-switch v-model="settings.essential" type="primary" disabled ></base-switch>
                        </div>
                        <!-- <div>
                            <span class="d-block">Multimedia</span>
                            <p>
                                Inhalte von Videoplattformen und Social-Media-Plattformen werden standardmäßig blockiert. Wenn Cookies von externen Medien akzeptiert werden, bedarf der Zugriff auf diese Inhalte keiner manuellen Einwilligung mehr.
                                <ul>
                                    <li>Youtube</li>
                                    <li>Facebook</li>
                                </ul>
                            </p>
                            <div>
                                <base-switch offText="Ja" onText="Nein" v-model="settings.marketing" type="primary"></base-switch>
                            </div>
                        </div> -->
                        <div>
                            <span class="d-block">Marketing Cookies</span>
                            <p>
                                Damit wir unsere Webseite verbessern können um Dir eine coole Besuchererfahrung zu bieten werden Daten an Marketing Tools gesendet.
                                <ul>
                                    <li>Google Tag Manager</li>
                                    <li>Google Analytics</li>
                                    <li>Google Ads</li>
                                    <li>Hubspot</li>
                                </ul>
                            </p>
                            <div>
                                <base-switch offText="Ja" onText="Nein" v-model="settings.marketing" type="primary"></base-switch>
                            </div>
                        </div>

                       </div>
                        <div class="d-flex justify-content-around w-100">
                            <base-button v-if="!settings.show" type="secondary" outline @click="settings.show = !settings.show">Einstellungen</base-button>
                            <base-button v-else type="secondary" outline @click="saveSettings">Speichern</base-button>
                            <base-button type="success" @click="acceptAll">Alle Akzeptieren</base-button>
                       </div>
                    </div>
                </div>
        </div>
    </SlideYUpTransition>
</template>

<script>
import { SlideYUpTransition } from "vue2-transitions";
const cookieKey = 'cookie-consent'
const cookieExpire = '30d'
export default {
   components: {
    SlideYUpTransition
  },
    data() {
        return {
            show: true,
            settings: {
                show: false,
                essential: true,
                marketing: false
            },
            cookie: false,
        }
    },
    mounted() {
        if (this.$cookies.isKey(cookieKey)) {
            console.log('cookie was there')
            this.syncSettings(false)
           this.show = false
      }
    },
    watch: {
        'settings.marketing'(newValue) {
            (true === newValue) ? this.enableGtm() : this.disableGtm();
        }
    },
    methods: {
        close() {
            this.show = false;
        },
        saveSettings() {
            $cookies.set(cookieKey, this.settings, cookieExpire)
            this.close()
        },
        acceptAll() {
            this.settings.marketing = true
            this.saveSettings()
        },
        enableGtm() {
            this.$gtm.enable(true)
            console.log('enabled')
        },
        disableGtm() {
            this.$gtm.enable(false)
            console.log('disabled')
        },
        syncSettings(tooCookie = true) {
            if (tooCookie) {
                $cookies.set(cookieKey, this.settings, '60s')
            } else {
              this.settings = this.$cookies.get(cookieKey)
            }
        },
    }
};
</script>

<style>
/* .card-consens {
  width: 350px;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d2d2dc;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(249, 249, 250);
  -moz-box-shadow: 0px 0px 5px 0px rgba(212, 182, 212, 1);
  box-shadow: 0px 0px 5px 0px rgb(161, 163, 164);
} */

/* .cookies a {
  text-decoration: none;
  color: #000;
  margin-top: 8px;
}

.cookies a:hover {
  text-decoration: none;
  color: blue;
  margin-top: 8px;
} */
</style>