<template>
                <form class="row justify-content-center mt--300" @submit.prevent="submitForm" ref="kontaktForm">
                    <div class="col-lg-8">
                        <card gradient="secondary" shadow body-classes="p-lg-5">
                            <h4 class="mb-1">Du möchtest mit uns arbeiten?</h4>
                            <p class="mt-0">Wir freuen uns auf Dein Projekt.</p>
                            <div class="row">
                                <div class="col-md-6">
                                       <base-input
                                        class=""
                                        alternative
                                        placeholder="Dein Name"
                                        addon-left-icon="ni ni-single-02"
                                        aria-required="true"
                                        :error="
                                            $v.form.name.$dirty && !$v.form.name.minLength
                                                    ? `Bitte gib mindestens ${$v.form.name.$params.minLength.min} Zeichen ein.`
                                                    : $v.form.name.$dirty && !$v.form.name.alpha
                                                        ? 'Bist du sicher, dass dies Dein richtiger Name ist?.'
                                                            : $v.form.name.$dirty && !$v.form.name.required
                                                                            ? 'Lass uns doch Deinen Namen wissen.'
                                                                            : ''
                                        "
                                        :value=form.name
                                        ref="nameInput"
                                        @blur="setName"
                                  ></base-input>
                                </div>
                                <div class="col-md-6">
                                     <base-input
                                    class=""
                                    alternative
                                    placeholder="Dein Nachname"
                                    addon-left-icon="ni ni-single-02"
                                    aria-required="true"
                                    :error="
                                        $v.form.surname.$dirty && !$v.form.surname.minLength
                                                ? `Bitte gib mindestens ${$v.form.surname.$params.minLength.min} Zeichen ein.`
                                                : $v.form.surname.$dirty && !$v.form.surname.alpha
                                                    ? 'Bist du sicher, dass dies Dein richtiger Nachame ist?.'
                                                        : $v.form.surname.$dirty && !$v.form.surname.required
                                                                        ? 'Lass uns doch Deinen Nachamen wissen.'
                                                                        : ''
                                    "
                                    :value=form.surname
                                    ref="surnameInput"
                                    @blur="setSurname"
                                 ></base-input>
                                </div>
                            </div>
                            <base-input
                                alternative
                                placeholder="Email Addresse"
                                :value=form.email
                                @blur="setEmail"
                                @keyup.enter="setEmail"
                                addon-left-icon="ni ni-email-83"
                                :error="getEmailError()"
                            ></base-input>

                            <base-input class="mb-4">
                                <textarea
                                    class="
                                        form-control form-control-alternative
                                    "
                                    name="name"
                                    rows="4"
                                    cols="80"
                                    placeholder="Hi, ich möchte gerne mit euch arbeiten. Meine aktuelle WebSeite findet ihr unter www.supercooleseite.de und ich würde gerne ..."
                                    v-model="form.message"
                                ></textarea>
                            </base-input>
                              <div v-if="success.value">
                                <base-alert type="success">
                                    <strong>Success</strong> Vielen Dank für Deine Nachricht!
                                    </base-alert>
                            </div>
                            <div v-if="error.value">
                            <base-alert type="danger">
                                <strong>Error</strong>  {{ error.message }}
                            </base-alert>
                            </div>
                            <base-button :disabled=!isValidated nativeType="submit" round block size="lg" >
                                 Jetzt Senden
                            </base-button>
                        </card>
                    </div>
                </form>
</template>
<script>

import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import axios from 'axios';
const { required, minLength, email , alpha} = require('vuelidate/lib/validators')
// const isFilled = (value) = !isEmpty(value)
const formDefaults = {
                name: '',
                surname: '',
                email: '',
                phone: '',
                terms: false,
                message: ''
            }
export default {
    name: 'KontaktForm',
    data() {
        return {
            form: { ...formDefaults },
            error: {
                value: false,
                message : ''
            },
            success: {
                value: false,
                message : ''
            }
        }
    },
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(3),
                alpha
            },
            surname: {
                 required,
                minLength: minLength(3),
                alpha
            },
            email: {
                required,
                email
            },
            message: {
                required,
                minLength: minLength(10)
            }
        },
    },
    computed: {
        isValidated() {
            return !this.$v.form.$invalid
        }
    },
    methods:{
        submitForm() {
            if(this.isValidated){
                axios.post(
                    'https://api.hsforms.com/submissions/v3/integration/submit/26201552/2299ee45-0aad-42e2-b554-41ffc2398873',
                    {
  "submittedAt": Date.now(),
  "fields": [
    {
      "objectTypeId": "0-1",
      "name": "email",
      "value": this.form.email
      },
        {
      "objectTypeId": "0-1",
      "name": "lastname",
      "value": this.form.surname
    },
    {
	  "objectTypeId": "0-1",
      "name": "firstname",
      "value": this.form.name
      },
        {
	  "objectTypeId": "0-1",
      "name": "message",
      "value": this.form.message
    }
  ],
  "context": {
    "pageUri": window.location.href,
    "pageName": document.title
  },
  "legalConsentOptions": {
    "consent": { // Include this object when GDPR options are enabled
      "consentToProcess": true,
      "text": "I agree to allow Example Company to store and process my personal data.",
      "communications": [
        {
          "value": true,
          "subscriptionTypeId": 999,
          "text": "I agree to receive marketing communications from Example Company."
        }
      ]
    }
  }
}
                )
                 .then((res) => {
                     //Perform Success Action
                     this.success.value = true,
                     this.success.message = res.data.inlineMessage || ''
                 })
                    .catch((error) => {
                         this.error = error|| ''
                     // error.response.status Check status code
                    }).finally(() => {

                        setTimeout(() => {
                            this.$refs.kontaktForm.reset();
                            this.form = formDefaults;
                            this.$v.$reset()
                            this.success.value = false
                        }, 5000)
                 });
            }
        },
        setName(event) {
            this.form.name = event.target.value
            this.$v.form.name.$touch()
        },
        setSurname(event) {
            this.form.surname = event.target.value
            this.$v.form.surname.$touch()
        },
        setEmail(event) {
                   this.form.email = event.target.value
            this.$v.form.email.$touch()
        },
        getEmailError() {
            const email = this.$v.form.email
            if (email.$dirty) {
                return email.required
                    ? email.$error
                        ? 'Vertippt? Dies ist keine gültige Email Adresse.'
                        : ''
                    : 'Bitte gib uns doch Deine Email Adresse damit wir dir Antworten können.'
            } else {
                return ''
            }
        }
    }
}
</script>
<style>
    button[type=submit]:disabled{
        cursor: not-allowed
    }
</style>