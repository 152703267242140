<template>
	<footer class="footer">
		<div class="container">
			<div class="row row-grid align-items-center my-md">
				<div class="col-lg-6">
					<h3 class="text-primary font-weight-light mb-2">Damit dein Projekt ein Erfolg ist!</h3>
				</div>
			</div>
			<hr />
			<div class="row align-items-center justify-content-md-between">
				<div class="col-md-6">
					<div class="copyright">
						&copy; {{ year }}
						<a href="/">WebServiceAndMore</a>
					</div>
				</div>
				<div class="col-md-6">
					<ul class="nav nav-footer justify-content-end">
						<li class="nav-item">
							<router-link to="/impressum" class="nav-link">Impressum</router-link>

						</li>
						<li class="nav-item">
							<router-link to="/datenschutz" class="nav-link">Datenschutz</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
export default {
	name: 'starter-footer',
	data() {
		return {
			year: new Date().getFullYear(),
		};
	},
};
</script>
<style></style>
