<template>
	<lottie-player id="webDev" ref="lottie" mode="normal" :src="src" autoplay loop="true"> </lottie-player>
</template>
<script>
// import LottieVuePlayer from '@lottiefiles/vue-lottie-player';
import * as animationData from './../../assets/lottie/fullstack.json';
import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';

export default {
	data() {
		return {
			src: JSON.stringify(animationData.default),
		};
    },
    props: {
        file: {
            type: String,
            default: ''
        }
    },
	mounted() {
		// 2. listen for player load. See lottie player repo for other events
		this.$refs.lottie.addEventListener('load', function() {
			console.log('loaded');
			// 3. configure the interactivity library
			create({
				mode: 'scroll',
				player: '#webDev',
				actions: [
					{
						visibility: [0, 1],
						type: 'seek',
						frames: [0, 100],
					},
				],
			});
		});
	},
};
</script>
