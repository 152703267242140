<template>
    <section class="section section-lg">
        <div class="container">
            <h4 class="display-4 mb-5 mt-5 text-center">Technologien</h4>
            <div class="d-flex justify-content-between">
                <node-js-icon size="5x" fill="#339933" class="mx-1"/>
                <php-icon size="5x" fill="#777BB4" class="mx-1"/>
                <postgre-sql-icon size="5x" fill="#4169E1" class="mx-1" />
                <word-press-icon size="5x" fill="#21759B" class="mx-1"/>
                <java-script-icon size="5x" fill="#F7DF1E" class="mx-1"/>
                <shopware-icon size="5x" fill="#189EFF" class="mx-1"/>
                <git-icon size="5x" fill="#F05032" class="mx-1"/>
                <react-icon size="5x" fill="#61DAFB" class="mx-1"/>
                <vue-js-icon size="5x" fill="#4FC08D" class="mx-1"/>
                <laravel-icon size="5x" fill="#FF2D20" class="mx-1" />
                <symfony-icon size="5x" fill="#1a171b" class="mx-1" />
                <my-sql-icon size="5x" fill="#4479A1" class="mx-1"/>
                <docker-icon size="5x" fill="#2496ED" class="mx-1"/>
            </div>
        </div>
    </section>
</template>


<script>
import {
    NodeJsIcon,
    WordPressIcon,
    JavaScriptIcon,
    PhpIcon,
    GitIcon,
    ReactIcon,
    VueJsIcon,
    LaravelIcon,
    SymfonyIcon,
    DockerIcon,
    MySqlIcon,
    ShopwareIcon,
    PostgreSqlIcon,
} from 'vue-simple-icons'
export default {
    components: {
        JavaScriptIcon,
        NodeJsIcon,
        LaravelIcon,
        SymfonyIcon,
        WordPressIcon,
        PhpIcon,
        GitIcon,
        ReactIcon,
        VueJsIcon,
        DockerIcon,
        MySqlIcon,
        ShopwareIcon,
        PostgreSqlIcon
        },
    props: { technologien: Array },
}
</script>


<style></style>
